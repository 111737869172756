<template>
  <div class="home">
    <Gallery
      :images="images"
      :imageWidth="imageWidth"
      :popUpMaxWidth="popUpMaxWidth"
      :mdCols="mdCols"
      :smCols="smCols"
      :xsCols="xsCols"
      :lgCols="lgCols"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import Gallery from "@/Gallery.vue";

export default {
  data() {
    return {
      imageWidth: 720,
      imageHeight: 450,
      popUpMaxWidth: 550,
      mdCols: 4,
      smCols: 4,
      xsCols: 6,
      lgCols: 3,

      images: [
        // { id: 1, imgSrc: require("../assets/1.jpg") },
        // { id: 2, imgSrc: require("../assets/2.jpg") },
        // { id: 3, imgSrc: require("../assets/3.jpg") },
        // { id: 4, imgSrc: require("../assets/4.jpg") },
        // { id: 5, imgSrc: require("../assets/5.jpg") },
        // { id: 6, imgSrc: require("../assets/6.jpg") },
        { id: 7, imgSrc: require("../assets/7.png") },
        { id: 8, imgSrc: require("../assets/8.png") },
        { id: 9, imgSrc: require("../assets/9.png") },
        { id: 10, imgSrc: require("../assets/10.png") },
        { id: 11, imgSrc: require("../assets/11.png") },
        { id: 12, imgSrc: require("../assets/12.png") },
        { id: 13, imgSrc: require("../assets/13.png") },
        { id: 14, imgSrc: require("../assets/14.png") },
        { id: 15, imgSrc: require("../assets/15.png") },
        { id: 16, imgSrc: require("../assets/16.jpg") },
        { id: 17, imgSrc: require("../assets/17.jpg") },
        { id: 18, imgSrc: require("../assets/18.png") },
        { id: 19, imgSrc: require("../assets/19.png") },
        { id: 20, imgSrc: require("../assets/20.png") },
        { id: 21, imgSrc: require("../assets/21.png") },
        { id: 22, imgSrc: require("../assets/22.png") },
        { id: 23, imgSrc: require("../assets/23.jpg") },
        { id: 24, imgSrc: require("../assets/24.jpg") },
        { id: 25, imgSrc: require("../assets/25.jpg") },
        { id: 26, imgSrc: require("../assets/26.jpg") },
        { id: 27, imgSrc: require("../assets/27.jpg") },
        { id: 28, imgSrc: require("../assets/28.jpg") },
        { id: 29, imgSrc: require("../assets/29.jpg") },
        { id: 30, imgSrc: require("../assets/30.jpg") },
      ]
    };
  },
  components: {
    Gallery
  }
};
</script>
